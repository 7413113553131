import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link,Switch } from "react-router-dom";
import Index from '../src/pages/index/index'
import Umerdoctor from '../src/pages/umerdoctor/umerdoctor'
import Partner from '../src/pages/partner/partner'
import Joinus from '../src/pages/joinus/joinus';
import Aboutus from '../src/pages/aboutus/aboutus'
import Latestdevelopments from '../src/pages/latestdevelopments/latestdevelopments'
import Latestdevelopments2 from '../src/pages/latestdevelopments2'
import Latestdevelopments3 from '../src/pages/latestdevelopments3'
import Latestdevelopments4 from '../src/pages/latestdevelopments4'
import Latestdevelopments5 from '../src/pages/latestdevelopments5'
import Latestdevelopments6 from '../src/pages/latestdevelopments6'
import Latestdevelopments7 from '../src/pages/latestdevelopments7'
import Latestdevelopments8 from '../src/pages/latestdevelopments8'
import Latestdevelopments9 from '../src/pages/latestdevelopments9'
import Latestdevelopments10 from './pages/latestdevelopments10'
import Latestdevelopments11 from './pages/latestdevelopments11'
import Latestdevelopments12 from './pages/latestdevelopments12'
import Latestdevelopments13 from './pages/latestdevelopments13'
import Latestdevelopments14 from './pages/latestdevelopments14'
import Latestdevelopments15 from './pages/latestdevelopments15'
import Latestdevelopments16 from './pages/latestdevelopments16'
import Latestdevelopments17 from './pages/latestdevelopments17'
import Latestdevelopments18 from './pages/latestdevelopments18'
import Artificialintelligence from '../src/pages/artificialintelligence/artificialintelligence'
import NoMatch from './pages/error_404'
import './index.css'
import logo from './images/logo.png'
import headerbg from './images/header.png'
import Footer from './components/Footer/Footer'
class routerindex extends Component {
    constructor(props) {
        super(props);
        this.state={
            tabactive:1
        }
    }
    componentDidMount(){
        if(sessionStorage.getItem('tabactive')){
            this.setState({
                tabactive:sessionStorage.getItem('tabactive')
            })
        }
    }
    handleAclick(e){
        this.setState({
            tabactive:e
        })
        sessionStorage.setItem('tabactive',e)
    }
    render() {
        return (
            <Router>
                <div>
                    <div className='header'>
                        <img className='img' src={logo}></img>
                        {/* <img src={headerbg} className='imgTwo'></img> */}
                        <ul className='routerul'>
                            <li className='routerli'> <Link className='link' to="/" onClick={this.handleAclick.bind(this,1)}>首页</Link>  <span className='liSpan'></span></li>
                            <li className='routerli'><Link className='link' to="/aboutus/" onClick={this.handleAclick.bind(this,2)}>关于我们</Link>  <span className='liSpan'></span></li>
                            <li className='routerli'><Link className='link' to="/umerdoctor/" onClick={this.handleAclick.bind(this,3)}>产品矩阵</Link>  <span className='liSpan'></span></li>
                            <li className='routerli'><Link className='link' to="/partner/" onClick={this.handleAclick.bind(this,4)}>合作伙伴</Link>  <span className='liSpan'></span></li>
                            <li className='routerli'><Link className='link' to="/Artificialintelligence/" onClick={this.handleAclick.bind(this,5)}>人工智能</Link>  <span className='liSpan'></span></li>
                            <li className='routerli'><Link className='link' to="/joinus/" onClick={this.handleAclick.bind(this,6)}>加入我们</Link>  <span className='liSpan'></span></li>
                        </ul>
                        <div className='header_line_main'>
                            <div className='header_line1'></div>
                            <ul className='header_line2'>
                                <li className={this.state.tabactive==1? 'activetab':'noactivetab'}>　　
                                    {this.state.tabactive==1&&<div>
                                        {/* <div className='leftrange'></div><div className='centerline centerlinesmall'></div><div className='rightrange'></div> */}
                                        <div className='centerblock'></div>
                                    </div>}
                                </li>
                                <li className={this.state.tabactive==2? 'activetab':'noactivetab'}>　　　　
                                    {this.state.tabactive==2&&<div>
                                        {/* <div className='leftrange'></div><div className='centerline'></div><div className='centerblock'></div><div className='rightrange'></div> */}
                                        <div className='centerblock'></div>
                                    </div>}
                                </li>
                                <li className={this.state.tabactive==3? 'activetab':'noactivetab'}>　　　　
                                    {this.state.tabactive==3&&<div>
                                        {/* <div className='leftrange'></div><div className='centerline'></div><div className='centerblock'></div><div className='rightrange'></div> */}
                                        <div className='centerblock'></div>
                                    </div>}
                                </li>
                                <li className={this.state.tabactive==4? 'activetab':'noactivetab'}>　　　　
                                    {this.state.tabactive==4&&<div>
                                        {/* <div className='leftrange'></div><div className='centerline'></div><div className='centerblock'></div><div className='rightrange'></div> */}
                                        <div className='centerblock'></div>
                                    </div>}
                                </li>
                                <li className={this.state.tabactive==5? 'activetab':'noactivetab'}>　　　　
                                    {this.state.tabactive==5&&<div>
                                        {/* <div className='leftrange'></div><div className='centerline'></div><div className='centerblock'></div><div className='rightrange'></div> */}
                                        <div className='centerblock'></div>
                                    </div>}
                                </li>
                                <li className={this.state.tabactive==6? 'activetab':'noactivetab'}>　　　　
                                    {this.state.tabactive==6&&<div>
                                        {/* <div className='leftrange'></div><div className='centerline'></div><div className='centerblock'></div><div className='rightrange'></div> */}
                                        <div className='centerblock'></div>
                                    </div>}
                                </li>
                            </ul>
                            <div className='header_line3'></div>
                        </div>
                        
                    </div>
                    <Switch>
                        <Route path="/" exact component={Index} />
                        <Route path="/umerdoctor/" component={Umerdoctor} />
                        <Route path="/partner/" component={Partner} />
                        <Route path="/aboutus/" component={Aboutus} />
                        <Route path="/Artificialintelligence/" component={Artificialintelligence} />
                        <Route path="/joinus/" component={Joinus} />
                        <Route path="/latestdevelopments/" component={Latestdevelopments} />
                        <Route path="/latestdevelopments2/" component={Latestdevelopments2} />
                        <Route path='/latestdevelopments3/' component={Latestdevelopments3}></Route>
                        <Route path='/latestdevelopments4/' component={Latestdevelopments4}></Route>
                        <Route path='/latestdevelopments5/' component={Latestdevelopments5}></Route>
                        <Route path='/latestdevelopments6/' component={Latestdevelopments6}></Route>
                        <Route path='/latestdevelopments7/' component={Latestdevelopments7}></Route>
                        <Route path='/latestdevelopments8/' component={Latestdevelopments8}></Route>
                        <Route path='/latestdevelopments9/' component={Latestdevelopments9}></Route>
                        <Route path='/latestdevelopments10/' component={Latestdevelopments10}></Route>
                        <Route path='/latestdevelopments11/' component={Latestdevelopments11}></Route>
                        <Route path='/latestdevelopments12/' component={Latestdevelopments12}></Route>
                        <Route path='/latestdevelopments13/' component={Latestdevelopments13}></Route>
                        <Route path='/latestdevelopments14/' component={Latestdevelopments14}></Route>
                        <Route path='/latestdevelopments15/' component={Latestdevelopments15}></Route>
                        <Route path='/latestdevelopments16/' component={Latestdevelopments16}></Route>
                        <Route path='/latestdevelopments17/' component={Latestdevelopments17}></Route>
                        <Route path='/latestdevelopments18/' component={Latestdevelopments18}></Route>
                        <Route component={NoMatch}></Route>
                    </Switch>
                    
                    {/* <Routers/> */}
                    <Footer></Footer>
                </div>

            </Router>
        );
    }
}

export default routerindex;

